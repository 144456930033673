import Api from '@/services/Api'

export default {
  verify(params) {
    return Api().post('user/verify/' + params)
  },
}



