<template>
    
    <!-- verifying user -->
    <div v-if="verified == -1" 
      class="rounded-md border border-sky-500 p-6 m-7 has-text-centered">
        <i class="fa fa-spinner fa-spin fa-2x"></i> 
        <div class="is-uppercase">Verifying your email... </div>
        Please contact the administrator for authorization!
    </div>
    
    <!-- verified user -->
    <div v-else-if="verified == 1" 
      class="rounded-md border border-green-500 p-6 m-7 has-text-centered text-green-800">
        <i class="fa fa-check fa-2x"></i> 
        <div class="is-uppercase">Email verified successfully!</div>
        Please contact the administrator.
    </div>

    <!-- not a user -->
    <div v-else-if="verified == 0" 
      class="rounded-md border border-red-500 p-6 m-7 has-text-centered text-red-800">
        <i class="fa fa-xmark fa-2x"></i> 
        <div class="is-uppercase">Something went wrong!</div>
        Please contact the administrator for authorization.
    </div>

</template>

<script>
import UserService from '@/services/UserService'
export default {
    data() {
        return {
          verified: -1,
        }

    },
    
    methods: {
        async verify() {
            try {
              const response = await UserService.verify(this.$route.params.token)
              // console.log(response)
              if(response.status == 200){
                setTimeout(() => {
                    this.$router.push('/login');
                }, 5000)
                this.verified = 1;
              }
            } catch (error) {
              this.verified = 0;
            }
        }
    
    },

    created(){
        this.verify()
    },

    computed: {
        loginInfo() { return this.$store.state.user }
    }
}   
</script>
